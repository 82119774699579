import {Gatekeeper} from 'gatekeeper-client-sdk';
import HttpService from './http';
const httpService = new HttpService();

export const loginByAuth = async (email, password) => {
    //const token = await Gatekeeper.loginByAuth(email, password);
    //const token = axios.post('login.php',{email,password}).then(result => {
    //    console.log(result);
    //});
    const token = await httpService.post('login.php',{email,password});
    if(token==null || !token.data.hasOwnProperty("token")){
        throw Error(token.data.message);
    }
    localStorage.setItem('token', token.data.token);
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token.data.token;
};

export const registerByAuth = async (email, password) => {
    const token = await Gatekeeper.registerByAuth(email, password);
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};

export const loginByGoogle = async () => {
    const token = await Gatekeeper.loginByGoogle();
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};

export const registerByGoogle = async () => {
    const token = await Gatekeeper.registerByGoogle();
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};

export const loginByFacebook = async () => {
    const token = await Gatekeeper.loginByFacebook();
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};

export const registerByFacebook = async () => {
    const token = await Gatekeeper.registerByFacebook();
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};
