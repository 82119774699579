const headers = {
    "Accept": "application/json",
    "Content-type": "application/json",
}
const axios = require('axios');

class HttpService {
    constructor(){
        this.apiUrl = process.env.REACT_APP_API_PATH;
    }

    post(url, data, config={ "headers": headers }){
        url = this.apiUrl + url;
        return axios.post(url,data,config).then(result => result);
        //return this.request(url, method, data).then(response => response.json());
    }

    get(url, config={ "headers": headers }){
        url = this.apiUrl + url;
        return axios.get(url,config).then(result => result);
        //return this.request(url, method, data).then(response => response.json());
    }

    postAndDownload(url,data,config={ "headers": headers }){
        url = this.apiUrl + url;
        axios({
            url: url, //your url
            method: 'POST',
            data: {data},
            responseType: 'blob', // important
            headers: config
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    // generateAndDownload(url,data,config={ "headers": headers }){
    //     url = this.apiUrl + url;
    // }
}

export default HttpService;