import React, {useState} from 'react';
import {useFormik} from 'formik';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import {Button, Input} from '@components';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import * as Yup from 'yup';

import Users from '../services/users';
const users = new Users();

const SetPassword = () => {
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();

    const css = `
    .setpass-cont {
        margin-top: 190px !important;
    }
    body{
        background-color: #eee;
    }
    .submit-btn{
        float: right;
        width: 200px;
    }`;

    const changePassword = async (password, re_password) => {
        try {
            setLoading(true);
            await users.setFirstPassword(password, re_password);
            toast.success('Contraseña modificada');
            setLoading(false);
            window.location.reload(false);
        } catch (error) {
            setLoading(false);
            toast.error(error.message || 'Error desconocido, por favor contactar el soporte.');
        }
    };

    const formik = useFormik({
        initialValues: {
            password: '',
            re_password: ''
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required('Obligatorio')
                .min(6, 'Contraseña muy corta'),
            re_password: Yup.string()
                .required('Obligatorio')
                .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
                .min(6, 'Contraseña muy corta'),
        }),
        onSubmit: (values) => {
            changePassword(values.password, values.re_password);
        }
    });

    return(
        <div class="container h-100">
            <style>{css}</style>
            <div class="row align-items-center h-100">
                <div class="col-8 mx-auto">
                    <div class="card h-100 border-primary justify-content-center setpass-cont">
                        <div class="card-header">
                            <h3 class="card-title">Cambiá tu contraseña</h3>
                            <div class="card-tools">
                                <span class="badge badge-primary">Telinfor Management</span>
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                        <div class="card-body">
                            <p><b>Tu usuario tiene una contraseña generada por defecto.</b><br></br>
                            Elegí una nueva contraseña para poder continuar:</p>
                            <div className="mb-3">
                            <Input
                                icon={faLock}
                                placeholder="Nueva contraseña"
                                type="password"
                                formik={formik}
                                formikFieldProps={formik.getFieldProps(
                                    'password'
                                )}
                            />
                            </div>
                            <div className="mb-3">
                            <Input
                                placeholder="Repetir nueva contraseña"
                                type="password"
                                formik={formik}
                                formikFieldProps={formik.getFieldProps(
                                    're_password'
                                )}
                            />
                            </div>
                        
                        </div>
                        <div class="card-footer">
                            Debe tener 6 carácteres como mínimo.
                            <Button block type="submit" isLoading={isLoading} theme="outline-primary btn-sm submit-btn">Cambiar</Button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetPassword;