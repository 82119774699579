import React, { useState, useEffect }  from 'react';
import Users from '../services/users';
import Plot from 'react-plotly.js';
import {toast} from 'react-toastify';
const users = new Users();

class Winners extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isPLoading: false,
            p_from: '2022-07-01T00:00',
            p_to: '2022-07-10T00:00',
            w_from: '2022-01-01T00:00',
            w_to: '2022-02-01T00:00',
            r_from: '2022-01-01T00:00',
            r_to: '2022-02-01T00:00',
            data: [{
                values: [1],
                labels: ['Esperando...'],
                domain: {column: 0},
                hoverinfo: 'label+value',
                hole: .4,
                type: 'pie'
              }],
            layout: {      
                title: 'Participaciones',
                annotations: [
                  {
                    font: {
                      size: 20
                    },
                    showarrow: false,      
                    text: 'Método'
                  }
                ],
                height: 350,
                width: 350,
                margin: {
                    l: 0,                
                    r: 0,                
                    b: 0,                
                    t: 80,                
                    pad: 4
                },
                showlegend: false,
                grid: {rows: 1, columns: 1}
            },
            winner: {
                name: '?',
                phone: '?',
                date: '?',
                method: '?'
            }
        };
        this.handleChange=this.handleChange.bind(this);
    }

    getParticipants = async () => {
        try {
            this.setState({isPLoading: true});
            const participants = await users.getParticipants(this.state.p_from, this.state.p_to);
            this.setState({
                data: [{
                values: [participants.data.mp,participants.data.sms,participants.data.ivr],
                labels: ['MP','SMS','IVR'],
                domain: {column: 0},
                hoverinfo: 'label+value',
                hole: .4,
                type: 'pie'
              }]});
            console.table(participants.data.sms);
            this.setState({isPLoading: false});
        } catch (error) {
            toast.error(error.message || 'Error desconocido, por favor contactar el soporte.');
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }

    componentDidMount() {
        console.log("Winners loaded");
    }

    render() {
        return(
            <div style={{"paddingBottom": "30px"}}>
            <div class="row">
            {/* BUSQUEDA DE CANTIDAD DE PARTICIPANTES */}
            <div class="col">
            <div class="card h-100 border-primary justify-content-center setpass-cont">
            <div class="card-header">
                <h3 class="card-title">Cantidad de participaciones</h3>
                <div class="card-tools">
                    <span class="badge badge-primary" style={{"backgroundColor": "#d52008"}}>⭐ La Noche del Domingo</span>
                </div>
            </div>
            <div class="card-body" style={{"textAlign": "left"}}>
                <div class="winners-datepick">
                    <label for="from_date">Desde: </label>&nbsp;
                    <input type="datetime-local" name="p_from" id="p_from_date" value={this.state.p_from} onChange={this.handleChange}/><br></br>
                    <label for="to_date">Hasta: </label>&nbsp;
                    <input type="datetime-local" name="p_to" id="p_to_date" value={this.state.p_to} onChange={this.handleChange}/>
                </div>
                <div class="winners-data">
                    <Plot data={this.state.data} layout={this.state.layout}/>
                </div>
            </div>
            <div class="card-footer">
                <button class="btn btn-primary" style={{"float": "right"}} onClick={this.getParticipants}>
                    {
                        this.state.isPLoading ?
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        :
                        <i class="fa fa-search" aria-hidden="true"></i>
                    }
                    &nbsp;Buscar
                </button>
            </div>
            </div>
            </div>
            {/* GENERACION DE GANADORES */}
            <div class="col">
            <div class="card h-100 border-primary justify-content-center setpass-cont">
            <div class="card-header">
                <h3 class="card-title">Generar un ganador</h3>
                <div class="card-tools">
                    <span class="badge badge-primary" style={{"backgroundColor": "#d52008"}}>⭐ La Noche del Domingo</span>
                </div>
            </div>
            <div class="card-body">
                <div class="winner-datepick">
                    <label for="from_date">Desde: </label>&nbsp;
                    <input type="datetime-local" name="w_from" id="w_from_date" value={this.state.w_from} onChange={this.handleChange}/><br></br>
                    <label for="to_date">Hasta: </label>&nbsp;
                    <input type="datetime-local" name="w_to" id="w_to_date" value={this.state.w_to} onChange={this.handleChange}/>
                </div>
                <div class="winner-data">
                    <h3>Ganador: </h3>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Teléfono</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Método</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{this.state.winner.name}</td>
                                <td>{this.state.winner.phone}</td>
                                <td>{this.state.winner.date}</td>
                                <td>{this.state.winner.method}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <div style={{"float": "left"}}>
                    <small><b>Info:</b> el ganador es seleccionado aleatoriamente, teniendo en cuenta que <br></br>
                    <u>1 SMS = 1 chance</u> | <u>1 ticket IVR = 1 chance</u> | <u>1 pago vía MP = 10 chances</u>.</small>
                </div>
                <button class="btn btn-success" style={{"float": "right"}} /*onClick={this.generateReport}*/>
                    <i class="fa fa-random" aria-hidden="true"></i>&nbsp;
                    Generar
                </button>
            </div>
            </div>
            </div>
            </div>
            <div class="row" style={{"marginTop": "20px"}}>
            {/* REPORTE DETALLADO DE PARTICIPACIONES */}
            <div class="col-8">
            <div class="card h-100 border-primary justify-content-center setpass-cont">
            <div class="card-header">
                <h3 class="card-title">Reporte detallado de participaciones</h3>
                <div class="card-tools">
                    <span class="badge badge-primary" style={{"backgroundColor": "#d52008"}}>⭐ La Noche del Domingo</span>
                </div>
            </div>
            <div class="card-body">
                <div class="winner-datepick">
                    <label for="from_date">Desde: </label>&nbsp;
                    <input type="datetime-local" name="r_from" id="r_from_date" value={this.state.r_from} onChange={this.handleChange}/><br></br>
                    <label for="to_date">Hasta: </label>&nbsp;
                    <input type="datetime-local" name="r_to" id="r_to_date" value={this.state.r_to} onChange={this.handleChange}/>
                </div>
                <div class="winner-data">
                    <table class="table table-bordered table-sm" id="sortTable">
                        <thead>
                            <tr>
                                <th scope="col">Nombre completo</th>
                                <th scope="col">Teléfono</th>
                                <th scope="col">Fecha de participación</th>
                                <th scope="col">Método</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>?</td>
                                <td>?</td>
                                <td>?</td>
                                <td>?</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <button class="btn btn-success" style={{"float": "right"}} /*onClick={this.generateReport}*/>
                    <i class="fa fa-file-excel" aria-hidden="true"></i>&nbsp;
                    Generar reporte
                </button>
            </div>
            </div>
            </div>
            {/* RESUMEN DE GENERACIONES DE GANADORES */}
            <div class="col-4">
            <div class="card h-100 border-primary justify-content-center setpass-cont">
            <div class="card-header">
                <h3 class="card-title">Ganadores generados</h3>
                <div class="card-tools">
                    <span class="badge badge-primary" style={{"backgroundColor": "#d52008"}}>⭐ La Noche del Domingo</span>
                </div>
            </div>
            <div class="card-body" style={{"textAlign": "left"}}>
                <div class="winner-data">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Nombre ganador</th>
                                <th scope="col">Teléfono ganador</th>
                                <th scope="col">Fecha de participación</th>
                                <th scope="col">Fecha de generación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Prueba Prueba</td>
                                <td>1155550000</td>
                                <td>10-07-2022 21:40:00</td>
                                <td>13-07-2022 10:30:00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
        );
    }

}

export default Winners;