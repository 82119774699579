import React, { useState, useEffect }  from 'react';
import Users from '../../services/users';
import {toast} from 'react-toastify';

const users = new Users();

class Generate extends React.Component {

    constructor(props){
        super(props);
        this.state = {from: '2022-01-01T00:00', to: '2022-02-01T00:00'};
        this.handleChange=this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }

    generateReport = async () => {
        try {
            users.generateReport(this.state.from, this.state.to);
        } catch (error) {
            toast.error(error.message || 'Error desconocido, por favor contactar el soporte.');
        }
    }

    render() {
        /*
        const hasLoaded = !this.state.isLoading;
        let footer;
        let header;
        let currentDate = new Date();
        let daysDifference;
        if(hasLoaded){
            daysDifference = Math.ceil(Math.abs(currentDate - new Date(this.report.date_from)) / (1000 * 60 * 60 * 24))-1;
            daysDifference = daysDifference==1 ? "ayer" : "hace "+daysDifference+" días";
            footer= <div style={{"display": "inline"}}><small>Reporte generado automaticamente el {this.moment(this.report.created_date).format('DD/MM/YYYY')}&nbsp;
            a las {this.moment(this.report.created_date).format('HH:mm:ss')}</small></div>;
            header= <span>Alertas del {this.moment(this.report.date_from).format('DD/MM/YYYY')} ({daysDifference})</span>;
        }*/
        return (
            <div class="card h-100 border-primary justify-content-center setpass-cont">
                <div class="card-header">
                    <h3 class="card-title">Generar reporte de alertas</h3>
                    <div class="card-tools">
                        <span class="badge badge-primary">Telco: TODAS</span>
                    </div>
                </div>
                <div class="card-body" style={{"textAlign": "center"}}>
                    <label for="from_date">Desde: </label>&nbsp;
                    <input type="datetime-local" name="from" id="from_date" value={this.state.from} onChange={this.handleChange}/><br></br>
                    <label for="to_date">Hasta: </label>&nbsp;
                    <input type="datetime-local" name="to" id="to_date" value={this.state.to} onChange={this.handleChange}/>
                </div>
                <div class="card-footer">
                    <button class="btn btn-success" style={{"float": "right"}} onClick={this.generateReport}>
                        <i class="fa fa-file-excel" aria-hidden="true"></i>&nbsp;
                        Generar reporte
                    </button>
                </div>
            </div>
        );
    }
}

export default Generate;