import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {MenuItem} from '@components';

export const MENU = [
    {
        name: 'menusidebar.label.dashboard',
        path: '/',
        icon: 'fa-poll'
    },
    {
        name: 'menusidebar.label.reports',
        icon: 'fa-table',
        children: [
            {
                name: 'TODAS',
                path: '/reports/all'
            },
            {
                name: 'Personal',
                path: '/reports/personal'
            },
            {
                name: 'Movistar',
                path: '/reports/movistar'
            },
            {
                name: 'Otras',
                path: '/reports/others'
            }
        ]
    },
    {
        name: 'menusidebar.label.winners',
        icon: 'fa-star',
        children: [
            {
                name: 'La Noche del Domingo',
                path: '/winners/noche-del-domingo'
            }
        ]
    },
    {
        name: 'menusidebar.label.stats',
        path: '/stats',
        icon: 'fa-chart-line'
    },
];

const css = `
    .tlf-main-btn{
        background-color: #fff;
        padding: 4px 0;
        border-radius: 4px;
    }
    .brand-text{
        color: #000;
    }`;

const MenuSidebar = () => {
    const user = useSelector((state) => state.auth.currentUser);

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <style>{css}</style>
            <Link to="/" className="brand-link">
                <div class="tlf-main-btn">
                <img
                    src="/img/tlf_icon.png"
                    alt="AdminLTE Logo"
                    className="brand-image"
                    style={{opacity: '.8'}}
                />
                <span className="brand-text font-weight-light">Management Web</span>
                </div>
            </Link>
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img
                            src={user.picture || '/img/default-profile.png'}
                            className="img-circle elevation-2"
                            alt="User"
                        />
                    </div>
                    <div className="info">
                        <Link to="/profile" className="d-block">
                            {user.email}
                        </Link>
                    </div>
                </div>
                <nav className="mt-2" style={{overflowY: 'hidden'}}>
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        role="menu"
                    >
                        {MENU.map((menuItem) => (
                            <MenuItem key={menuItem.name} menuItem={menuItem} />
                        ))}
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default MenuSidebar;
