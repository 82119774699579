import React from 'react';
import SmallBox from '../components/small-box/SmallBox';

const Dashboard = () => {
    return (
        <div className="container-fluid">
            {/*
            <div className="row">
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={150}
                        title={process.env.REACT_APP_API_PATH} 
                        type="info"
                        navigateTo="/"
                        icon="ion-android-people"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={53}
                        title="Bounce Rate"
                        type="success"
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={44}
                        title="User Registrations"
                        type="warning"
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={65}
                        title="Unique Visitors"
                        type="danger"
                        navigateTo="/"
                    />
                </div>
            </div>
             */}
            <iframe src="http://172.16.64.26:8080/stats/ng/#/home" style={{height: "800px", width: "100%", border: "none"}} title="Adnetwork stats"></iframe> 
        </div>
    );
};

export default Dashboard;
