import React, { useState, useEffect }  from 'react';
import Report from '../components/reports/Report';
import Generate from '@app/components/reports/Generate';
import Users from '../services/users';
const users = new Users();

let date_from = null;
let date_now = new Date();

const Reports = (props) => {
    //const [isLoading, setLoading] = useState(false);
    var moment = require('moment');

    useEffect(() => {
        console.log('loaded');
        //getLastReport('AlertasPersonalAr','last',null,'diario');
    }, []);

    var result = [];

    switch(props.type){
        case "personal":
            result.push(
                <div class="col-12 mx-auto">
                    <Report key="personalAr" type="AlertasPersonalAr" moment="last" periodicity="diario" date={null} badge_color="#74ceea" badge_title="Personal Argentina 🇦🇷"></Report>
                    <Report key="personalPy" type="AlertasPersonalPy" moment="last" periodicity="diario" date={null} badge_color="#cc4c4c" badge_title="Personal Paraguay 🇵🇾"></Report>
                </div>
            );
            break;
        case "movistar":
            result.push(
                <div class="col-12 mx-auto">
                    <Report key="movistarAr" type="AlertasMovistarAr" moment="last" periodicity="diario" date={null} badge_color="#74ceea" badge_title="Movistar Argentina 🇦🇷"></Report>
                    <Report key="movistarUy" type="AlertasMovistarUy" moment="last" periodicity="diario" date={null} badge_color="#2c3fd2" badge_title="Movistar Uruguay 🇺🇾"></Report>
                </div>
            );
            break;
        case "all":
            result.push(
                <div class="col-12 mx-auto">
                    <Generate></Generate>
                </div>
            )
    }

    return(
        <div>{result}</div>
    );
}

export default Reports;