import React, { useState, useEffect }  from 'react';

class ReportTable extends React.Component {
    constructor(props){
        super(props);
        this.data=JSON.parse(props.data);
    }

    numberWithPoints(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
    }

    render(){
        let tableHeader = [];
        let rows = [];
        let total = 0;
        let totalColSpan = 0;
        let montoTotal = 0;
        switch(this.props.type){
            case "AlertasPersonalAr":
                rows = [];
                total = 0;
                totalColSpan = 2;
                tableHeader.push(
                    <tr style={{'background-color': '#f1f1f1'}}>
                        <th>Servicio</th>
                        <th>Código corto</th>
                        <th>Cantidad</th>
                        <th>Monetizado</th>
                    </tr>
                );
                for(var shortCode in this.data.report){
                    let isFirst = true;
                    for(var service of this.data.report[shortCode]){
                        total+=service.cantidad;
                        rows.push(
                            <tr>
                                <td>{service.name}</td>
                                {isFirst ? <td rowSpan={this.data.report[shortCode].length} style={{'vertical-align': 'middle'}}>{shortCode}</td> : null}
                                <td>{service.cantidad}</td>
                                <td>${this.numberWithPoints(Math.round(service.cantidad*20.28*100)/100)}</td>
                            </tr>
                        );
                        isFirst = false;
                    }
                }
                montoTotal = "$"+this.numberWithPoints(Math.round(total*20.28*100)/100);
                break;
            case "AlertasPersonalPy":
                rows = [];
                total = 0;
                totalColSpan = 2;
                tableHeader.push(
                    <tr style={{'background-color': '#f1f1f1'}}>
                        <th>Servicio</th>
                        <th>Código corto</th>
                        <th>Cantidad</th>
                        <th>Monetizado</th>
                    </tr>
                );
                for(var shortCode in this.data.report){
                    let isFirst = true;
                    for(var service of this.data.report[shortCode]){
                        total+=service.cantidad;
                        rows.push(
                            <tr>
                                <td>{service.name}</td>
                                {isFirst ? <td rowSpan={this.data.report[shortCode].length} style={{'vertical-align': 'middle'}}>{shortCode}</td> : null}
                                <td>{service.cantidad}</td>
                                <td>₲{this.numberWithPoints(Math.round(service.cantidad*990*100)/100)}</td>
                            </tr>
                        );
                        isFirst = false;
                    }
                }
                montoTotal = "₲"+this.numberWithPoints(Math.round(total*990*100)/100);
                break;
            case "AlertasMovistarAr":
                rows = [];
                total = this.data.cantidadTotal;
                montoTotal = "$"+this.numberWithPoints(this.data.montoTotal);
                let tarifas = [];
                for(var shortCodeData of this.data.shortCodeReports){
                    for(var tarifa in shortCodeData.tarifas){
                        if (tarifas.indexOf(tarifa) === -1) tarifas.push(tarifa);
                    }
                }
                tarifas.sort(function(a, b){return a-b});
                totalColSpan = tarifas.length+1;
                for(var shortCodeData of this.data.shortCodeReports){
                    let tarifasRow = [];
                    for(var tarifa of tarifas){
                        if(shortCodeData.tarifas[tarifa]!=null){
                            tarifasRow.push(<td>{shortCodeData.tarifas[tarifa]}</td>);
                        }else{
                            tarifasRow.push(<td style={{"background-color": "#fdd"}}>0</td>);
                        }
                    }
                    rows.push(
                        <tr>
                            <td>{shortCodeData.shortCode}</td>
                            {tarifasRow}
                            <td>{shortCodeData.cantidad}</td>
                            <td>${this.numberWithPoints(shortCodeData.monto)}</td>
                        </tr>
                    )
                }
                let tarifasHeader = [];
                for(var tarifa of tarifas){
                    tarifasHeader.push(
                        <th>{tarifa}</th>
                    );
                }
                tableHeader.push(
                    <tr style={{'background-color': '#f1f1f1'}}>
                        <th>Código corto</th>
                        {tarifasHeader}
                        <th>Cantidad</th>
                        <th>Monetizado</th>
                    </tr>
                );
                break;
            case "AlertasMovistarUy":
                rows = [];
                total = 0;
                totalColSpan = 1;
                tableHeader.push(
                    <tr style={{'background-color': '#f1f1f1'}}>
                        <th>Servicio</th>
                        <th>Cantidad</th>
                        <th>Monetizado</th>
                    </tr>
                );
                for(var service of this.data){
                    total+=(+service[1]);
                    rows.push(
                        <tr>
                            <td>{service[0]}</td>
                            <td>{service[1]}</td>
                            <td>UY${this.numberWithPoints(Math.round(service[1]*35*100)/100)}</td>
                        </tr>
                    )
                }
                montoTotal = "UY$"+this.numberWithPoints(Math.round(total*35*100)/100);
                break;
        }
        return(
            <div>
                <table class="table table-bordered table-sm" id="sortTable">
                    <thead>
                        {tableHeader}
                    </thead>
                    {rows}
                    <tbody>
                        <tr style={{'background-color': '#e0f5ff'}}>
                            <td colSpan={totalColSpan}>TOTAL</td>
                            <td>{total}</td>
                            <td>{montoTotal}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ReportTable;