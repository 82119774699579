import React, { useState, useEffect }  from 'react';
import Users from '../../services/users';
import {toast} from 'react-toastify';
import ReportTable from './ReportTable';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const users = new Users();

class Report extends React.Component {
    moment = require('moment');
    date_now = new Date();

    constructor(props){
        super(props);
        this.state = {isLoading: true};
    }

    componentDidMount(){
        console.log("Component mounted! "+this.props.type);
        if(this.props.type!=null){
            this.getReport();
        }
    }

    getReport = async () => {
        try {
            this.setState({isLoading: true});
            const response = await users.getAutogeneratedStat(this.props.type, this.props.moment, this.props.date, this.props.periodicity);
            this.report=response.data;
            this.setState({isLoading: false});
        } catch (error) {
            this.setState({isLoading: false});
            toast.error(error.message || 'Error desconocido, por favor contactar el soporte.');
        }
    }

    downloadReport = async () => {
        try {
            users.downloadReport(this.props.type, this.report.data, this.props.periodicity, this.report.date_from, this.report.created_date);
        } catch (error) {
            toast.error(error.message || 'Error desconocido, por favor contactar el soporte.');
        }
    }

    render() {
        const hasLoaded = !this.state.isLoading;
        let footer;
        let header;
        let currentDate = new Date();
        let daysDifference;
        if(hasLoaded){
            daysDifference = Math.ceil(Math.abs(currentDate - new Date(this.report.date_from)) / (1000 * 60 * 60 * 24))-1;
            daysDifference = daysDifference==1 ? "ayer" : "hace "+daysDifference+" días";
            footer= <div style={{"display": "inline"}}><small>Reporte generado automaticamente el {this.moment(this.report.created_date).format('DD/MM/YYYY')}&nbsp;
            a las {this.moment(this.report.created_date).format('HH:mm:ss')}</small></div>;
            header= <span>Alertas del {this.moment(this.report.date_from).format('DD/MM/YYYY')} ({daysDifference})</span>;
        }
        return (
            <div class="card h-100 border-primary justify-content-center setpass-cont">
                <div class="card-header">
                    <h3 class="card-title">{header}</h3>
                    <div class="card-tools">
                        <span class="badge badge-primary" style={{'background-color': this.props.badge_color}}>{this.props.badge_title}</span>
                    </div>
                </div>
                <div class="card-body">
                    {
                    this.state.isLoading ?
                    <div class="text-center">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <ReportTable type={this.props.type} data={this.report.data}></ReportTable>
                    }
                </div>
                <div class="card-footer">
                    {footer}
                    <button class="btn btn-success" style={{"float": "right"}} onClick={this.downloadReport}>
                        <i class="fa fa-file-excel" aria-hidden="true"></i>&nbsp;
                        Descargar XLS
                    </button>
                </div>
            </div>
        );
    }
}

export default Report;