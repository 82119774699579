import HttpService from './http';
const httpService = new HttpService();

class Users {
    constructor() {
        this.token = null;
    }

    getUser = async () => {
        this.token = localStorage.getItem("token");
        const config = { 
            "headers": {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": `Bearer ${this.token}`
        }};
        const response = await httpService.get('getuser.php',config);
        if(response.data.success!==1){
            throw Error(response.data.message);
        }
        return response.data.user;
    }

    setFirstPassword = async (password, re_password) => {
        this.token = localStorage.getItem("token");
        const config = { 
            "headers": {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": `Bearer ${this.token}`
        }};
        const response = await httpService.post('setnewpass.php',{password, re_password},config);
        if(response.data.success!==1){
            throw Error(response.data.message);
        }
        return;
    }

    getAutogeneratedStat = async (type, moment, date_from, periodicity) => {
        this.token = localStorage.getItem("token");
        const config = { 
            "headers": {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": `Bearer ${this.token}`
        }};
        const response = await httpService.post('getreport.php',{type, moment, date_from, periodicity},config);
        if(response.data.success!==1){
            throw Error(response.data.message);
        }
        return response.data;
    }

    downloadReport = (type, data, periodicity, date_from, created_date) => {
        this.token = localStorage.getItem("token");
        const config = { 
            "Accept": "application/*",
            "Content-type": "application/*",
            "Authorization": `Bearer ${this.token}`
        };
        httpService.postAndDownload('downloadreport.php',{type, data, periodicity, date_from, created_date},config);
        // if(response.data.success==0){
        //     throw Error(response.data.message);
        // }
        // return response.data;
    }

    generateReport = (date_from, date_to) => {
        this.token = localStorage.getItem("token");
        const config = { 
            "Accept": "application/*",
            "Content-type": "application/*",
            "Authorization": `Bearer ${this.token}`
        };
        httpService.postAndDownload('generatereport.php',{date_from, date_to},config);
    }

    getParticipants = async (date_from, date_to) => {
        this.token = localStorage.getItem("token");
        const config = { 
            "Accept": "application/*",
            "Content-type": "application/*",
            "Authorization": `Bearer ${this.token}`
        };
        const response = await httpService.post('getparticipants.php',{date_from, date_to},config);
        if(response.data.success!==1){
            throw Error(response.data.message);
        }
        return response.data;
    }
}

export default Users;